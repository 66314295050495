import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCookie, getBoot, setCookie, eraseCookie } from "../../utils";
import Login from "../modals/Login.jsx";
import Registration from "../modals/Registration.jsx";
import "./navigation.css";
import LogoBlanco from "../../assets/images/LogoBlanco.png";
import IconoLenguaje from "../../assets/images/icons/Lenguaje.svg";
import IconoMenu from "../../assets/images/icons/menu.png";

class Navigation extends Component {
  state = {
    boot: getBoot()[getCookie("OroAcalang")],
    currentlang: "",
  };
  showLangMenu = () => {
    let x = document.getElementById("selectorLenguaje");
    if (x.style.display === "none" || x.style.display === "") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };
  showMenuMovil = () => {
    const menuMovil = document.getElementById("menuMovil");
    const enlaces = document.querySelectorAll("#menuMovil li");
    menuMovil.classList.toggle("abierto");

    enlaces.forEach((link) => {
      link.classList.toggle("fade");
    });
  };
  fillActiveLang = () => {
    let lang = getCookie("OroAcalang");
    if (lang === "es") {
      let activelang = document.getElementById("idiomaEspanol");
      let unactivelang = document.getElementById("idiomaIngles");
      activelang.classList.add("botonActivo");
      unactivelang.classList.remove("botonActivo");
    } else {
      let unactivelang = document.getElementById("idiomaEspanol");
      let activelang = document.getElementById("idiomaIngles");
      activelang.classList.add("botonActivo");
      unactivelang.classList.remove("botonActivo");
    }
  };
  changeLang = (lang) => {
    setCookie("OroAcalang", lang, 20);
    window.location.reload();
  };

  componentDidMount() {
    this.fillActiveLang();
    this.getActivePage();
  }

  getActivePage = () => {
    if (this.props.activo === "legales" || this.props.activo === undefined) {
    } else {
      let active = document.getElementById(this.props.activo);
      active.classList.add("enlaceActivo");
    }
  };
  render() {
    return (
      <>
        <div className="contenedor">
          <nav>
            <Link to="/">
              <img
                src={LogoBlanco}
                alt="Logo Blanco"
                className="logoNavegacion"
              />
            </Link>
            <div className="enlacesNavegacion">
              <div className="enlacesEscritorio">
                <ul>
                  <li>
                    <Link to="/"> {this.state.boot.nav.inicio}</Link>
                  </li>
                  <li>
                    <Link to="/glosario">{this.state.boot.nav.glosario}</Link>
                  </li>
                  <li>
                    <Link to="/ebook">{this.state.boot.nav.ebooks}</Link>
                  </li>
                  <li>
                    <Link to="/calendario">
                      {this.state.boot.nav.calendario}
                    </Link>
                  </li>
                  <li onClick = {e => eraseCookie()} style={this.props.session?{display: "inline-block"}:{display: "none"}} >
                    <Link to="/">Cerrar sesión</Link>
                  </li>
                </ul>
                <Login id="Desktop" session={this.props.session} />
                <Registration id="RegistrationDesktop" session={this.props.session}/>
              </div>
              <div className="ajustesLenguaje">
                <button
                  className="botonMostrarLenguaje"
                  onClick={(e) => this.showLangMenu()}
                >
                  <img
                    src={IconoLenguaje}
                    alt="Icono Lenguaje"
                    className="iconoLenguaje"
                  />
                </button>
                <div className="selectorLenguaje" id="selectorLenguaje">
                  <button
                    onClick={(e) => this.changeLang("es")}
                    id="idiomaEspanol"
                  >
                    Español
                  </button>
                  <button
                    onClick={(e) => this.changeLang("en")}
                    id="idiomaIngles"
                  >
                    English
                  </button>
                </div>
                <button
                  onClick={(e) => this.showMenuMovil()}
                  className="menuMovil"
                >
                  <img src={IconoMenu} alt="Menu" />
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className="enlacesMoviles" id="menuMovil">
          <li>
            <Link to="/"> {this.state.boot.nav.inicio}</Link>
          </li>
          <li>
            <Link to="/glosario">{this.state.boot.nav.glosario}</Link>
          </li>
          <li>
            <Link to="/ebook">{this.state.boot.nav.ebooks}</Link>
          </li>
          <li>
            <Link to="/calendario">{this.state.boot.nav.calendario}</Link>
          </li>
          <li onClick = {e => eraseCookie()} style={this.props.session?{display: "inline-block"}:{display: "none"}} >
            <Link to="/">Cerrar sesión</Link>
          </li>
          <li>
            <div className="contenedorBotonMovil">
              <Login id="Mobile" />
              <Registration id="RegistrationMobile" />
            </div>
          </li>
        </div>
      </>
    );
  }
}

export default Navigation;
