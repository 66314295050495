import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Modal } from "reactstrap";
import swal from "sweetalert";
import "./home.css";
import {
  getCookie,
  getBoot,
  getBaseApi,
  setCookie,
  getUrlParms,
} from "../../utils";
import Navigation from "../../components/navigation/navigation";
import Footer from "../../components/footer/footer";
import InicioImagen from "../../assets/images/slide_inicio.png";
import IconoVideo1 from "../../assets/images/icons/comercio.svg";
import IconoVideo2 from "../../assets/images/icons/analisis.svg";
import IconoVideo3 from "../../assets/images/icons/psicologia.svg";
import IconoVideo4 from "../../assets/images/icons/estrategia.svg";
import IconoVideo5 from "../../assets/images/icons/social.svg";
import IconoVideo6 from "../../assets/images/icons/acciones.svg";
import IconoVideo7 from "../../assets/images/icons/coins-1.svg";
import IconoVideo8 from "../../assets/images/icons/metatrader-4-logo-og.png";
import IconoVideo9 from "../../assets/images/icons/Indicadores.svg";
import close from "../../assets/images/icons/close.svg";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session_id: getCookie("session_id"),
      crm_id: getCookie("crm_id"),
      modal_video: false,
      titulo: "",
      lista: [
        {
          titulo: "Introducción",
          url: "https://academics.orotrader.com/videos/Unidad 1/6.mp4",
          indice: "0",
        },
      ],
      url: "",
      indice: "",
      progress: {},
      boot: getBoot()[getCookie("OroAcalang")],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (getCookie("session_id") && getCookie("crm_id")) {
      this.getProgress();
    } else {
      this.checkAutoLogin();
    }
  }
  checkAutoLogin = () => {
    let token = getUrlParms("token");
    if (token !== undefined && token !== "") {
      fetch(getBaseApi() + "/check/Login", {
        method: "POST",
        body: JSON.stringify({
          token: token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.result === 1) {
            this.setState({
              session_id: result.data.id,
              crm_id: result.data.crm_id,
            });
            setCookie("session_id", result.data.id, 2);
            setCookie("crm_id", result.data.crm_id, 2);
            if (result.data.insert) {
              this.insertProgress();
            } else {
              this.getProgress();
            }
          } else {
            swal({
              icon: "error",
              title: "Sorry something went wrong",
              text: this.state.boot[result.error],
            });
          }
        });
    }
  };
  insertProgress = () => {
    fetch(getBaseApi() + "/manage/Progress", {
      method: "POST",
      body: JSON.stringify({
        session_id: this.state.session_id,
        action: "insert",
        crm_id: this.state.crm_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result === 1) {
          this.getProgress();
        } else {
          swal({
            icon: "error",
            title: "Sorry something wrong",
            text: this.state.boot[result.error],
          });
        }
      });
  };
  getProgress = () => {
    fetch(getBaseApi() + "/manage/Progress", {
      method: "POST",
      body: JSON.stringify({
        session_id: this.state.session_id,
        action: "get",
        crm_id: this.state.crm_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result === 1) {
          this.setState({ progress: result.data });
        } else {
          swal({
            icon: "error",
            title: "Sorry something wrong",
            text: this.state.boot[result.error],
          });
        }
      });
  };
  mostrarVideosCategoria = (id, e) => {
    e.preventDefault();
    this.setState({
      titulo: this.state.boot.centro["video_seccion" + id],
      lista: this.state.boot.centro.videos["unidad" + id],
      modal_video: true,
      url: this.state.boot.centro.videos["unidad" + id][0].url,
    });
  };
  renderLista = () => {
    let render = [];
    let pref = this.state.lista;
    if (pref !== undefined && pref !== {}) {
      for (let i = 0; i < pref.length; i++) {
        if (i === 0) {
          render.push(
            <div
              className="activa"
              id={"video" + i}
              key={i}
              onClick={(e) =>
                this.setState({ indice: pref[i].indice, url: pref[i].url })
              }
            >
              <h6>
                <span>{[i + 1]}.-</span> {pref[i].titulo}
              </h6>
            </div>
          );
        } else {
          render.push(
            <div
              key={i}
              id={"video" + i}
              onClick={(e) => {
                this.setState({ indice: pref[i].indice, url: pref[i].url });
                this.activeVideoList(i);
              }}
            >
              <h6>
                <span>{[i + 1]}.-</span> {pref[i].titulo}
              </h6>
            </div>
          );
        }
      }
    }

    return render;
  };
  activeVideoList(index) {
    let pref = this.state.lista;
    for (let i = 0; i < pref.length; i++) {
      document.getElementById("video" + i).classList.remove("activa");
    }
    document.getElementById("video" + index).classList.add("activa");
  }
  updateProgress = () => {
    let videos = this.state.progress.videos;
    videos[this.state.indice] = 1;
    let a = videos.join("");
    fetch(getBaseApi() + "/manage/Progress", {
      method: "POST",
      body: JSON.stringify({
        session_id: this.state.session_id,
        action: "update",
        crm_id: this.state.crm_id,
        id: this.state.progress.id2mercados_education_progress,
        videos: a,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result === 1) {
          this.setState({ progress: result.data });
        } else {
          swal({
            icon: "error",
            title: "Sorry something wrong",
            text: this.state.boot[result.error],
          });
        }
      });
  };
  render() {
    return (
      <>
        <Navigation session={this.state.session_id ? true : false} />
        <div className="contenedor home">
          <div className="intro">
            <div className="textoIntro">
              <span>{this.state.boot.centro.titulo_inicial}</span>
              <p>{this.state.boot.centro.subtitulo_inicial}</p>
            </div>
            <div className="imagenIntro">
              <img src={InicioImagen} alt="Centro" />
            </div>
          </div>
          <div className="videos">
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("1", e)}
            >
              <img src={IconoVideo1} alt="IconoVideo" />
              <span>{this.state.boot.centro.video_seccion1}</span>
              <p>16 videos</p>
            </div>
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("2", e)}
            >
              <img src={IconoVideo2} alt="IconoVideo" />
              <span>{this.state.boot.centro.video_seccion2}</span>
              <p>11 videos</p>
            </div>
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("3", e)}
            >
              <img src={IconoVideo3} alt="IconoVideo" />
              <span>{this.state.boot.centro.video_seccion3}</span>
              <p>10 videos</p>
            </div>
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("4", e)}
            >
              <img src={IconoVideo4} alt="IconoVideo" />
              <span>{this.state.boot.centro.video_seccion4}</span>
              <p>10 videos</p>
            </div>
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("5", e)}
            >
              <img src={IconoVideo5} alt="IconoVideo" />
              <span>{this.state.boot.centro.video_seccion5}</span>
              <p>5 videos</p>
            </div>
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("6", e)}
            >
              <img src={IconoVideo6} alt="IconoVideo" />
              <span>{this.state.boot.centro.video_seccion6}</span>
              <p>8 videos</p>
            </div>
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("7", e)}
            >
              <img src={IconoVideo7} alt="IconoVideo" />
              <span>{this.state.boot.centro.video_seccion7}</span>
              <p>6 videos</p>
            </div>
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("8", e)}
            >
              <img src={IconoVideo8} alt="IconoVideo" />

              <span>{this.state.boot.centro.video_seccion8}</span>
              <p>9 videos</p>
            </div>
            <div
              className="temaVideo"
              onClick={(e) => this.mostrarVideosCategoria("9", e)}
            >
              <img src={IconoVideo9} alt="IconoVideo" />
              <span>{this.state.boot.centro.video_seccion9}</span>
              <p>16 videos</p>
            </div>
          </div>
        </div>
        <Footer />
        <Modal className="modalVideos" isOpen={this.state.modal_video}>
          <img
            src={close}
            alt=""
            onClick={(e) => {
              this.setState({ modal_video: false });
            }}
            className="iconoCerrar"
          />
          <h5 id="tituloCategoriaVideo">{this.state.titulo}</h5>
          <div className="contenedorVideos">
            <p>Lista de videos</p>
            <div className="listaVideos">{this.renderLista()}</div>
            <div className="reproductorVideos">
              <div className="sinVideos">
                <p>{this.state.boot.centro.sin_video}</p>
              </div>
              <ReactPlayer
                className="reactPlayer"
                {...this.props}
                url={this.state.url}
                controls
                onEnded={this.updateProgress}
                width="100%"
                // height="100%"
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
