import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
import { Modal } from "reactstrap";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer";
// import close from "../../assets/images/icons/close.svg";
import Ebook3 from "../../assets/images/ebooks_3.jpg";
import Ebook2 from "../../assets/images/ebooks_2.jpg";
import Ebook1 from "../../assets/images/ebooks_1.jpg";
import "./ebook.css";

export default class Ebook extends Component {
  state = {
    boot: getBoot()[getCookie("OroAcalang")],
    session_id: getCookie("session_id"),
    iframe: false,
    ebook: 0,
    ebooks: {
      1: "https://alphanoticias-blog.com/view/1.pdf",
      2: "https://alphanoticias-blog.com/view/2.pdf",
      3: "",
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Navigation session={this.state.session_id ? true : false} />
        <div className="contenedor ebook">
          <span>Ebooks</span>
          <div className="contenedorLibros">
            <div className="libro">
              <img src={Ebook1} alt="Portada" />
              <span>Las bases para tener exito con operaciones en divisas</span>
              <button
                className="boton botonAmarillo"
                onClick={(e) => this.setState({ iframe: true, ebook: 1 })}
              >
                {this.state.boot.books.leer}
              </button>
            </div>
            <div className="libro">
              <img src={Ebook2} alt="Portada" />

              <span>Las bases para tener exito en el comercio de Acciones</span>
              <button
                className="boton botonAmarillo"
                onClick={(e) => this.setState({ iframe: true, ebook: 2 })}
              >
                {this.state.boot.books.leer}
              </button>
            </div>
            <div className="libro">
              <img src={Ebook3} alt="Portada" />

              <span>Las bases para tener exito en el comercio de CFD</span>
              <button
                className="boton botonAmarillo"
                onClick={(e) => this.setState({ iframe: true, ebook: 3 })}
              >
                {this.state.boot.books.leer}
              </button>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.iframe} className="modalEbook">
          <iframe
            className="contenedorPDF"
            title="libro1iFrame"
            src={this.state.ebooks[this.state.ebook]}
          ></iframe>
        </Modal>
        <Footer />
      </>
    );
  }
}
