import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
import "./calendario.css";
import Navigation from "../../components/navigation/navigation";
import Footer from "../../components/footer/footer";

export default class Calendario extends Component {
  state = {
    boot: getBoot()[getCookie("OroAcalang")],
    session_id: getCookie("session_id"),
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.calendar();
  }
  onScriptLoad = () => {
    let parametros = {
      colorTheme: "ligth",
      isTransparent: false,
      width: "100%",
      height: "100%",
      locale: "es",
      importanceFilter: "-1,0,1",
    };
    return JSON.stringify(parametros);
  };
  calendar = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
    script.async = true;
    script.textContent = this.onScriptLoad();
    document.getElementById("Calendario").appendChild(script);
  };

  render() {
    return (
      <>
        <Navigation session={this.state.session_id ? true : false} />
        <div className="contenedor calendario">
          <h1>{this.state.boot.calendario.titulo}</h1>
          <div className="contenedorCalendario">
            <div className="tradingview-widget-container">
              <div
                className="tradingview-widget-container__widget"
                id="Calendario"
              ></div>
              <div className="tradingview-widget-copyright"></div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
