import React, { Component } from "react";
import { getCookie, setCookie } from "./utils";
import { BrowserRouter, Route } from "react-router-dom";
import Inicio from "./pages/home/home.jsx";
import Legals from "./pages/legals/legals.jsx";
import Ebook from "./pages/ebooks/ebooks.jsx";
import Calendario from "./pages/calendario/calendario.jsx";
import Glosario from "./pages/glosario/glosario.jsx";

class App extends Component {
  render() {
    if (getCookie("OroAcalang") === null) {
      setCookie("OroAcalang", "es", 20);
      window.location.reload();
    }
    return (
      <BrowserRouter>
        <Route path="/" exact component={Inicio} />
        <Route path="/politicas" exact component={Legals} />
        <Route path="/ebook" component={Ebook} />
        <Route path="/calendario" component={Calendario} />
        <Route path="/glosario" component={Glosario} />
      </BrowserRouter>
    );
  }
}

export default App;
