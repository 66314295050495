import React, { Component } from "react";
import { getCookie, getBoot, getBaseApi, getUrlParms } from "../../utils";
import { Modal } from "reactstrap";
import swal from "sweetalert";
import "././General.css";
import Logo from "../../assets/images/LogoNegro.png";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abierto: getUrlParms("token") || getCookie("session_id") ? false : true,
      registro_abierto: false,
      boot: getBoot()[getCookie("OroAcalang")],
    };
    
  }
  componentDidMount() {
    this.getCode();
  }

  getCode() {
    fetch("https://api.ipregistry.co/?key=69fhxelduhtk43")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            code: result.location.country.code,
          });
        },
        (error) => {
          this.setState({
            code: "",
          });
        }
      );
  }
  renderPaises = () => {
    let render = [];
    let pref = this.state.boot.countries;
    let code = this.state.code;
    if (this.state.code === "" || this.state.code === "US") {
      code = "PE";
    }
    if (pref !== undefined && pref !== []) {
      for (let i = 0; i < pref.length; i++) {
        if (pref[i].code !== code) {
          render.push(
            <option key={pref[i].code} value={pref[i].code}>
              {pref[i].country}
            </option>
          );
        } else {
          render.push(
            <option key={pref[i].code} value={pref[i].code} selected>
              {pref[i].country}
            </option>
          );
        }
      }
    }
    return render;
  };
  SignUp = (e) => {
    e.preventDefault();
    let f_country = document.getElementById("pais" + this.props.id).value;
    let phoneCountryCode = "";
    document.getElementById("btnSignUp" + this.props.id).value = "Loading...";
    document.getElementById("btnSignUp" + this.props.id).disabled = true;
    fetch("https://restcountries.eu/rest/v2/alpha/" + f_country)
      .then((res) => res.json())
      .then((restc_data) => {
        phoneCountryCode = "+" + restc_data.callingCodes;
        fetch("https://www.orotrader.com/api/signUp2.php", {
          method: "POST",
          body: JSON.stringify({
            idnombre: document.getElementById("nombre" + this.props.id).value,
            idapellidos: document.getElementById("apellido" + this.props.id)
              .value,
            idcorreo: document.getElementById("email" + this.props.id).value,
            idphone: document.getElementById("telefono" + this.props.id).value,
            idpass: document.getElementById("pass" + this.props.id).value,
            country: f_country,
            phonecode: phoneCountryCode,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.result.result === 1) {
              window.open = (result.result.data.session_url, "_blank");
              document.getElementById(
                "tpInput" + this.props.id
              ).value = document.getElementById("email" + this.props.id).value;
              document.getElementById(
                "passwordInput" + this.props.id
              ).value = document.getElementById("pass" + this.props.id).value;
              this.getLogin(e);
            } else {
              document.getElementById(
                "btnSignUp" + this.props.id
              ).value = this.props.boot.modales.crear_cuenta;
              document.getElementById(
                "btnSignUp" + this.props.id
              ).disabled = false;
              swal({
                icon: "error",
                title: this.props.boot.modales.swal_error_title,
                // text: this.props.boot[result.error],
                text: result.message,
              });
            }
          });
      });
  };
  getLogin = (e) => {
    e.preventDefault();
    fetch(getBaseApi() + "/get/Login", {
      method: "POST",
      body: JSON.stringify({
        user: document.getElementById("tpInput" + this.props.id).value,
        password: document.getElementById("passwordInput" + this.props.id)
          .value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result === 1) {
          let protocol = window.location.protocol;
          let host = window.location.host;
          window.location.href =
            protocol + "//" + host + "/?token=" + result.data.token;
        } else {
          swal({
            icon: "error",
            title: "Sorry something went wrong",
            text: this.state.boot[result.error],
          });
        }
      });
  };
  render() {
    return (
      <>
        <button
          style={
            this.props.session
              ? { display: "none" }
              : { display: "inline-block" }
          }
          className="botonAmarillo"
          onClick={(e) => {
            this.setState({ abierto: true });
          }}
        >
          {this.state.boot.modales.iniciar}
        </button>
        <Modal isOpen={this.state.abierto} className="contenedorModal">
          <img src={Logo} alt="Logo" />
          <span>{this.state.boot.modales.iniciar_sesion}</span>
          <form onSubmit={(e) => this.getLogin(e)}>
            <label htmlFor="tpInput">{this.state.boot.modales.usuario}</label>
            <br />
            <input
              name="usuario"
              type="text"
              id={"tpInput" + this.props.id}
              required
              placeholder={this.state.boot.modales.usuario}
            />
            <br />

            <label htmlFor="passwordInput">
              {this.state.boot.modales.contrasena}
            </label>
            <br />
            <input
              name="contrasena"
              type="password"
              id={"passwordInput" + this.props.id}
              required
              placeholder={this.state.boot.modales.contrasena}
            />
            <br />

            <button className="botonContrasena">
              {this.state.boot.modales.olvide_contrasena}
            </button>
            <input
              type="submit"
              value={this.state.boot.modales.boton_iniciar_sesion}
            />
          </form>
          <div className="contenedorRegistrate">
            <p>{this.state.boot.modales.pregunta_registrado}</p>
            <button
              onClick={(e) => {
                this.setState({ registro_abierto: true });
              }}
              className="botonRegistrarse"
            >
              {this.state.boot.modales.registrarse}
            </button>
          </div>
        </Modal>
        <Modal isOpen={this.state.registro_abierto} className="contenedorModal">
          <img src={Logo} alt="Logo" />

          <span>{this.state.boot.modales.registrarse}</span>
          <form
            className="formularioRegistrarse"
            onSubmit={(e) => this.SignUp(e)}
          >
            <label htmlFor="nombre">{this.state.boot.modales.nombre}</label>
            <br />
            <input
              name="nombre"
              type="text"
              id={"nombre" + this.props.id}
              required
              placeholder={this.state.boot.modales.nombre}
            />
            <label htmlFor="apellido">{this.state.boot.modales.apellido}</label>
            <br />
            <input
              name="apellido"
              type="text"
              id={"apellido" + this.props.id}
              required
              placeholder={this.state.boot.modales.apellido}
            />
            <label htmlFor="email">{this.state.boot.modales.correo}</label>
            <br />
            <input
              name="correo"
              type="email"
              id={"email" + this.props.id}
              required
              placeholder={this.state.boot.modales.correo}
            />
            <label htmlFor="telefono">{this.state.boot.modales.telefono}</label>
            <br />
            <input
              name="telefono"
              type="tel"
              id={"telefono" + this.props.id}
              required
              placeholder={this.state.boot.modales.telefono}
            />
            <label htmlFor="pais">{this.state.boot.modales.pais}</label>
            <br />
            <select name="pais" id={"pais" + this.props.id}>
              {this.renderPaises()}
            </select>
            <br />
            <label htmlFor="pass">{this.state.boot.modales.contrasena}</label>
            <br />
            <input
              name="contrasena"
              type="password"
              id={"pass" + this.props.id}
              pattern="^(?=.*[a-zA-Z])(?=\w*[0-9])[^_\W]{6,12}$"
              required
              title={this.state.boot.modales.label_contrasena}
              placeholder={this.state.boot.modales.contrasena}
            />
            <p className="lblFormat">
              {this.state.boot.modales.label_contrasena}
            </p>

            <input
              type="submit"
              className="botonVerde"
              id={"btnSignUp" + this.props.id}
              value={this.state.boot.modales.crear_cuenta}
            />
          </form>
        </Modal>
      </>
    );
  }
}

export default SignIn;
