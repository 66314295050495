import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer";

import "./glosario.css";

export default class Glosario extends Component {
  state = {
    session_id: getCookie("session_id"),
    boot: getBoot()[getCookie("OroAcalang")],
    abecedario: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderABC = () => {
    let botones = [];
    let abecedario = this.state.abecedario;

    abecedario.forEach((letra) => {
      botones.push(
        <button key={letra} onClick={(e) => this.openBlock({ letra })}>
          {letra}
        </button>
      );
    });

    return botones;
  };

  showLetter = (letra) => {
    let render = [];
    let pref = this.state.boot.glosario[letra];
    if (pref !== undefined && pref !== {}) {
      for (let i = 0; i < pref.titulos.length; i++) {
        render.push(
          <div key={i}>
            <span>{pref.titulos[i]}</span>
            <p>{pref.textos[i]}</p>
          </div>
        );
      }
    }
    return render;
  };
  openBlock = (letra) => {
    let letraElegida = letra.letra;

    let bloques = document.querySelectorAll(".letraUnica");
    for (let index = 0; index < bloques.length; index++) {
      bloques[index].style.display = "none";
    }
    let bloqueActivo = document.getElementById("bloque" + letraElegida);
    bloqueActivo.style.display = "block";
    console.log(bloques);
  };

  renderBlocks = () => {
    let abecedario = this.state.abecedario;
    let bloques = [];
    abecedario.forEach((letra) => {
      bloques.push();
    });
    console.log(bloques);
    return bloques;
  };

  render() {
    return (
      <>
        <Navigation session={this.state.session_id ? true : false} />
        <div className="contenedor glosario">
          <div className="contenedorGlosario">
            <span>{this.state.boot.glosario.titulo}</span>
            <div className="botonesGlosario">{this.renderABC()}</div>
            <div className="bloqueLetras">
              <div className="auxiliarLetras">
                <div className="letraUnica" id="bloquea">
                  {this.showLetter("a")}
                </div>
                <div className="letraUnica" id="bloqueb">
                  {this.showLetter("b")}
                </div>
                <div className="letraUnica" id="bloquec">
                  {this.showLetter("c")}
                </div>
                <div className="letraUnica" id="bloqued">
                  {this.showLetter("d")}
                </div>
                <div className="letraUnica" id="bloquee">
                  {this.showLetter("e")}
                </div>
                <div className="letraUnica" id="bloquef">
                  {this.showLetter("f")}
                </div>
                <div className="letraUnica" id="bloqueg">
                  {this.showLetter("g")}
                </div>
                <div className="letraUnica" id="bloqueh">
                  {this.showLetter("h")}
                </div>
                <div className="letraUnica" id="bloquei">
                  {this.showLetter("i")}
                </div>
                <div className="letraUnica" id="bloquej">
                  {this.showLetter("j")}
                </div>
                <div className="letraUnica" id="bloquek">
                  {this.showLetter("k")}
                </div>
                <div className="letraUnica" id="bloquel">
                  {this.showLetter("l")}
                </div>
                <div className="letraUnica" id="bloquem">
                  {this.showLetter("m")}
                </div>
                <div className="letraUnica" id="bloquen">
                  {this.showLetter("n")}
                </div>
                <div className="letraUnica" id="bloqueo">
                  {this.showLetter("o")}
                </div>
                <div className="letraUnica" id="bloquep">
                  {this.showLetter("p")}
                </div>
                <div className="letraUnica" id="bloqueq">
                  {this.showLetter("q")}
                </div>
                <div className="letraUnica" id="bloquer">
                  {this.showLetter("r")}
                </div>
                <div className="letraUnica" id="bloques">
                  {this.showLetter("s")}
                </div>
                <div className="letraUnica" id="bloquet">
                  {this.showLetter("t")}
                </div>
                <div className="letraUnica" id="bloqueu">
                  {this.showLetter("u")}
                </div>
                <div className="letraUnica" id="bloquev">
                  {this.showLetter("v")}
                </div>
                <div className="letraUnica" id="bloquew">
                  {this.showLetter("w")}
                </div>
                <div className="letraUnica" id="bloquex">
                  {this.showLetter("x")}
                </div>
                <div className="letraUnica" id="bloquey">
                  {this.showLetter("y")}
                </div>
                <div className="letraUnica" id="bloqueq">
                  {this.showLetter("q")}
                </div>
                <div className="letraUnica" id="bloquez">
                  {this.showLetter("z")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
